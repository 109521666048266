<!--  -->
<template>
  <el-menu
    :collapse="isCollage"
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    background-color="#1C2237"
    text-color="#BFCBD9"
    active-text-color="#419DFF"
  >
    <div class="logo">
      <img class="logo-pic" :src="require('@/assets/images/imooc.jpeg')" v-if="isCollage" />
      <div class="logo-text" v-if="!isCollage">校园二手交易系统</div>
    </div>
    <el-menu-item :index="item.path" v-for="item in noChildren" :key="item.path" @click="clickMenu(item)">
      <i :class="'el-icon-' + item.icon"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu :index="item.label" v-for="(item, index) in hasChildren" :key="index">
      <template slot="title">
        <i :class="'el-icon-' + item.icon"></i>
        <span>{{ item.label }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item :index="subItem.path" v-for="(subItem, subIndex) in item.children" :key="subIndex" @click="clickMenu(subItem)">
          <i :class="'el-icon-' + subItem.icon"></i>
          <span>{{ subItem.label }}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item index="6" @click="goto_imooc">
      <i class="el-icon-search"></i>
      <span slot="title">外链</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  computed: {
    noChildren() {
      return this.asideMenu.filter(item => !item.children)
    },
    hasChildren() {
      return this.asideMenu.filter(item => item.children)
    },
    isCollage() {
      return this.$store.state.tab.isCollapse
    }
  },
  methods: {
    goto_imooc() {
      window.open('https://www.imooc.com')
    },
    clickMenu(item) {
      this.$router.push({ name: item.name })
      this.$store.commit('selectMenu', item)
    },
    get_router() {
      this.$api.user.getRouter().then(res => {
        let router_data = res.data.data
        let response_router = []
        for (const router in router_data) {
          if (router_data[router]['parent_router_id'] === null) {
            let router_dict = {
              router_id: router_data[router]['router_id'],
              path: router_data[router]['path'],
              name: router_data[router]['name'],
              label: router_data[router]['label'],
              icon: router_data[router]['icon']
            }
            response_router.push(router_dict)
          }
        }
        for (const router in router_data) {
          if (router_data[router]['parent_router_id'] !== null) {
            const parent_router_id = router_data[router]['parent_router_id']
            for (const parent_router in response_router) {
              if (response_router[parent_router]['router_id'] === parent_router_id) {
                if (!response_router[parent_router].hasOwnProperty('children')) {
                  response_router[parent_router]['children'] = []
                }
                let append_children = {
                  path: router_data[router]['path'],
                  name: router_data[router]['name'],
                  label: router_data[router]['label'],
                  icon: router_data[router]['icon']
                }
                response_router[parent_router]['children'].push(append_children)
              }
            }
          }
        }
        this.asideMenu = response_router
      })
    }
  },
  data() {
    return {
      asideMenu: []
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.get_router()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {}
}
</script>
<style lang="scss" scoped>
.el-menu {
  height: 100%;
  border: none;
}
.logo {
  display: flex;
  justify-content: center;
  height: 60px;
  color: #ffffff;
}
.logo-text {
  margin-top: 20px;
}
.logo-pic {
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-top: 15px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
