<!--  -->
<template>
  <el-container style="height:100%;">
    <el-aside width="auto"><common-aside></common-aside></el-aside>

    <el-container>
      <el-header><common-header></common-header></el-header>
      <common-tab></common-tab>
      <el-main>
        <router-view></router-view>
        <div>
          <span class="beian">Copyright © 2023 tcpjwtester.top All Rights Reserved . <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2021042560号-1</a></span>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import CommonAside from '@/components/CommonAside'
import CommonHeader from '@/components/CommonHeader'
import CommonTab from '@/components/CommonTab'
export default {
  data() {
    return {}
  },
  components: {
    CommonAside,
    CommonHeader,
    CommonTab
  }
}
</script>
<style scoped>
.beian{
  display: flex;
  justify-content: center;
  color: #8c939d;
  margin-top: 30px;
}
/* @import url(); 引入css类 */
.el-header {
  background-color: #1c2237;
}
*::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  background-color: transparent;
} /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
*::-webkit-scrollbar-track {
  background-color: #1c2237;
} /*定义滚动条轨道 内阴影+圆角*/
*::-webkit-scrollbar-thumb {
  background-color: #1c2237;
  border-radius: 6px;
} /*定义滑块 内阴影+圆角*/
.scrollbarHide::-webkit-scrollbar {
  display: none;
}
.scrollbarShow::-webkit-scrollbar {
  display: block;
}
</style>
