<!--  -->
<template>
  <header>
    <div class="l-content">
      <div @click="collapseMenu">
        <icon :name="collapse_icon_name" class="collapse-flod"></icon>
      </div>
      <!-- <el-button plain icon="el-icon-s-operation" size="mini" @click="collapseMenu"></el-button> -->
      <!-- <span :class="isCollapse? 'el-icon-s-unfold':'el-icon-s-fold'" @click="collapseMenu" /> -->

      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="current.path" v-if="current">
          {{ current.label }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown @command="handleCommand" trigger="hover">
        <span class="el-dropdown-link">
          <el-row>
            <el-avatar v-if="head_img_url" :src="head_img_url" size="medium" fit="fill"></el-avatar>
            <el-avatar v-else>{{ user_name }}</el-avatar>
          </el-row>
        </span>
        <el-dropdown-menu slot="dropdown" size="mini">
          <el-dropdown-item command="person">个人资料</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { removeToken, removeTablist } from '@/utils/auth'

export default {
  computed: {
    ...mapState({
      current: state => state.tab.currentMenu
    }),
    isCollapse() {
      return this.$store.state.tab.isCollapse
    }
  },
  created() {
    this.getUserName()
  },
  data() {
    return {
      userImg: require('../assets/images/user.png'),
      collapse_icon_name: 'angle-left',
      user_name: '',
      head_img_url: ''
    }
  },
  methods: {
    collapseMenu() {
      this.$store.commit('collapseMenu')
      if (this.isCollapse) {
        this.collapse_icon_name = 'angle-right'
      } else {
        this.collapse_icon_name = 'angle-left'
      }
    },
    getUserName() {
      this.$api.user.getLoginUserInfo().then(res => {
        this.user_name = res.data['data']['user']
        this.head_img_url = res.data.data.head_img
      })
    },
    handleCommand(command) {
      switch (command) {
        case 'logout':
          removeToken()
          removeTablist()
          // this.$store.dispatch('user/logout')
          this.$router.push('/login')
          break
        case 'person':
          this.$router.push('/personal_information')
          break
      }
    }
  }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.l-content {
  display: flex;
  align-items: center;
  .el-button {
    margin-right: 20px;
  }
}
.r-content {
  .user {
    color: #ffffff;
    font-size: 15px;
    margin-top: 5px;
  }
}
.collapse-flod {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: #fff;
}
</style>

<style lang="scss">
.el-breadcrumb__item {
  .el-breadcrumb__inner {
    color: #fff;
    font-weight: normal;
  }
  &:last-child {
    .el-breadcrumb__inner {
      color: #fff;
    }
  }
}
.el-dropdown-menu.el-popper {
  margin-top: 30px;
}
</style>
